<template>
  <div class="pay">
    <van-toast id="custom-selector" />
    <!--备注弹窗 -->
    <transition name="fade">
      <div class="pop_remarks" v-show="showNote" @click="handleCancelRemarks()">
        <div class="remarks_box" @click.stop="">
          <div class="title">添加备注</div>
          <div class="input">
            <input
              type="text"
              v-model="note"
              placeholder="收款人可见，最多20个字"
              autofocus
              maxlength="20"
            />
          </div>
          <div class="btns">
            <span @click.stop="handleCancelRemarks()">取消</span>
            <span @click.stop="handleSubmitRemarks()">确定</span>
          </div>
        </div>
      </div>
    </transition>
    <!-- 支付弹窗键盘-->
    <van-popup
      :overlay="false"
      v-model="show"
      position="bottom"
      :style="{ height: '4.8rem' }"
      @click-overlay="popup"
    >
      <div class="key_box">
        <KeyBoard
          ref="goodKeyBoard"
          @finish="handleChangeGoodFinish"
          :k_decimal="2"
          :k_finish_disabled="Number(subData.pay_amount) <= 0"
          k_finish_text="确定"
          @input="handleChangeGoodInput"
        ></KeyBoard>
      </div>
    </van-popup>

    <!-- 路径不正确状态-->
    <transition name="fade">
      <div class="loading" v-if="loading">
        <van-loading />
      </div>
    </transition>

    <div class="box">
      <div class="top">
        <div class="name">
          {{ merchant_info.merchant_name }}({{ merchant_info.bosser }})
        </div>
        <div class="label">付款金额(元)：</div>
        <div class="input_boxitem">
          <div class="input_box" @click="pop_up">
            <div class="input_mark">￥</div>
            <div class="input_number">
              <span>{{ subData.pay_amount }} </span>
              <p class="cursor"></p>
            </div>
          </div>
        </div>
        <div class="tips">温馨提示：付款前请确认金额</div>
        <div class="remark_btn" @click="handleClickRemarks()">
          <span>{{ subData.note }}</span>
          {{ subData.note ? "修改" : "添加备注" }}
        </div>
      </div>
    </div>

    <!--支付弹框-->
    <van-popup
      round
      v-model="pay_show"
      @close="pay_close"
      closeable
      position="bottom"
      class="heig"
    >
      <div class="pay_num">
        <div class="pay_num_1">实付金额</div>
        <van-badge
          :content="parseFloat(amount) > 0 ? `优惠` + amount + `元` : ''"
        >
          <div class="pay_num_2box">
            <div class="pay_num_2"><span>￥</span>{{ pay_amount || 0 }}</div>
            <span class="discounts"></span>
          </div>
        </van-badge>
      </div>
      <van-cell title="支付方式" :value="radio" is-link @click="xz_payment" />
      <van-cell
        center
        v-if="
          market_score_conf.use_market_score == 1 &&
          parseFloat(market_score_amount_expect) > 0 &&
          parseInt(market_score_conf.market_score_every_day_max) >
          parseInt(market_score_use_number)
        "
      >
        <template #title>
          <div class="custom_item">市场积分抵扣</div>
          <div class="custom-title" v-if="customer_use_market_score == 1">
            使用{{ market_score_cost | filterZero }}积分,抵扣{{
              market_score_amount
            }}元
          </div>
          <div class="custom-title" v-if="customer_use_market_score == 0">
            使用{{ market_score_cost_expect | filterZero }}积分,最多抵扣{{
              market_score_amount_expect
            }}元
          </div>
        </template>

        <template #right-icon>
          <van-switch
            v-model="customer_use_market_score"
            active-color="#6dd572"
            size="24"
            active-value="1"
            inactive-value="0"
            @change="handleChangeUseMarketScore"
          />
        </template>
      </van-cell>
      <van-cell
        center
        v-if="
          market_score_conf.use_m_score == 1 &&
          parseFloat(merchant_score_amount_expect) > 0 &&
          parseInt(merchant_score_conf.m_score_every_day_max) >
            parseInt(merchant_score_use_number)
        "
      >
        <template #title>
          <div class="custom_item">档口积分抵扣</div>
          <div class="custom-title" v-if="customer_use_merchant_score == 1">
            使用{{ merchant_score_cost | filterZero }}积分,抵扣{{
              merchant_score_amount
            }}元
          </div>
          <div class="custom-title" v-if="customer_use_merchant_score == 0">
            使用{{ merchant_score_cost_expect | filterZero }}档口积分,最多抵扣{{
              merchant_score_amount_expect
            }}元
          </div>
        </template>
        <template #right-icon>
          <van-switch
            v-model="customer_use_merchant_score"
            active-color="#6dd572"
            size="24"
            active-value="1"
            inactive-value="0"
            @change="handleChangeUseMerchantScore"
          />
        </template>
      </van-cell>
      <van-cell
        center
        @click="isclickfliter"
        v-if="action == 1 ? true : true"
        is-link
      >
        <template #title>
          <div class="custom-title2">市场劵</div>
        </template>
        <template #right-icon>
          <span class="style_red" v-if="ticket_amount > 0">{{
            -ticket_amount + "元" || ""
          }}</span>
          <span v-else>暂无优惠</span>
          <van-icon name="arrow" color="#865002" size="0.25rem" />
        </template>
      </van-cell>

      <!--档口-->
      <van-cell
        center
        @click="isMerchantTikcetsList"
        v-if="action == 1 ? true : true"
        is-link
      >
        <template #title>
          <div class="custom-title2">档口劵</div>
        </template>
        <template #right-icon>
          <span class="style_red" v-if="ticket_merchant_amount">{{
            -ticket_merchant_amount + "元" || "暂无优惠"
          }}</span>
          <span v-else>暂无优惠</span>
          <van-icon name="arrow" color="#865002" size="0.25rem" />
        </template>
      </van-cell>
      <div class="pay_button">
        <button class="pay_but" @click="payment">确定支付</button>
      </div>
    </van-popup>

    <!--支付方式弹框-->
    <van-popup
      class="heig2"
      v-model="payment_show"
      closeable
      close-icon="arrow-left"
      position="bottom"
      close-icon-position="top-left"
      @close="payment_close"
    >
      <div class="order">
        订单金额：<span>￥{{ subData.pay_amount }}</span>
      </div>
      <van-radio-group :value="radio" v-model="radio" @change="radioradio">
        <van-cell-group>
          <van-cell clickable>
            <template #title>
              <div class="wx_title">
                <template v-if="pay == '微信支付'"
                  ><img class="wx" src="./../assets/img/wx.png"
                /></template>
                <template v-if="pay == '支付宝支付'"
                  ><img class="wx" src="./../assets/img/zfb2.png"
                /></template>
                <div>{{ pay }}</div>
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="pay" />
            </template>
          </van-cell>
        </van-cell-group>
        <!--断当前的环境(余额支付)-->
        <van-cell-group v-if="channel_type == '2' ? true : false">
          <van-cell clickable>
            <template #title>
              <div class="wx_title">
                <img class="wx" src="./../assets/img/yuer.png" />
                <div>余额支付(余额{{wallet}}元)</div>
              </div>
            </template>
            <template #right-icon>
              <van-radio  :disabled="isdisabled" name="余额支付" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-popup>
  </div>
</template>

<script>
import KeyBoard from "@/components/KeyBoard";
import Vue from "vue";
import { Toast } from "vant";
import { v4 as uuidv4 } from "uuid";
Vue.use(Toast);
var cavalue = null;
import {
  pay_getMerchantInfo,
  getdiscountcouponlist,
  baseUri,
  requestPay,
  getCustomerScore,
  getquery_can_get_coupon,
} from "@/api/pay";

import { setCookie } from "@/utils/index";
export default {
  name: "pay",
  components: {
    KeyBoard,
  },
  data() {
    return {
      pay_walletamount:0,   //余额支付金额
      isdisabled:true,   //是否可以用余额支付
      radio: "微信支付", //单选框的选择
      pay: "微信支付", //环境的支付区分
      wallet: "", //是否使用余额支付
      pay_show: false, //  支付弹框
      showNote: false, // 添加备注相关弹框
      payment_show: false, //支付方式
      show: true, //是否显示支付弹框
      loading: true, //是否正确的获取信息
      // 金额相关
      subData: {
        qr_uuid:"",
        merchant_id: "",
        pay_amount: "",
        note: "", //评价相关信息
        market_id: "",
        mid: "",
        amount: 0, //优惠多少元
        customer_id: "", //优惠券的ID
        title: "", //优惠卷的描述信息
        trade_no: "", //票证信息
        index: "", //
        booth_id: "", //获取的参数
        merchant_title: "",
        merchant_trade_no: "",
      },

      checked: true, //按钮开关开启
      uuidvalue: "", //获取到uuid
      note: "",
      baseUri: "",
      ticket_merchant_amount: 0, //商户档口卷
      ticket_amount: 0,
      market_score_conf: {
        use_market_score: 0,
        market_score: 0,
        market_score_max: 0,
        market_score_every_day_max: 0,
      },
      merchant_score_conf: {
        use_m_score: 0,
        merchant_score: 0,
        merchant_score_max: 0,
        merchant_score_every_day_max: 0,
      },
      customer_use_market_score: "1",
      customer_use_merchant_score: "1",
      market_score_use_number: 0,
      merchant_score_use_number: 0,
      market_score: 0,
      merchant_score: 0,

      amount: 0, //总优惠多少元
      pay_amount: 0, //优惠后多少元
      // 小键盘相关
      focus_key_board: "",
      merchant_info: "",
      listarr: "", //获取优惠券的所有信息
      listmerchantarr: [], //商户档口优惠券
      isclick: 1, // 是否可以点击
      //isactivebj: true, //没有优惠券的时候
      action: "", //当前是哪一个浏览器
      channel_type: "", //给后端判断当前是什么支付的
      payChannel: "", //支付方式,
      market_score_amount: 0,
      market_score_cost: 0,
      //期待
      market_score_amount_expect: 0,
      market_score_cost_expect: 0,

      merchant_score_amount: 0,
      merchant_score_cost: 0,

      //期待
      merchant_score_amount_expect: 0,
      merchant_score_cost_expect: 0,
      select_ticket_index: 0,
      select_merchant_ticket_index: 0,
      pay_amount_no_ticket: 0,
    };
  },

  created() {
    this.setUUid();
    let urlaction = this.browserJudgment();
    if (urlaction == 1) {
      this.action = urlaction;
      this.channel_type = 2;
      this.radio="微信支付"
      this.pay = "微信支付";
    } else if (urlaction == 2) {
      this.channel_type = 1;
      this.radio="支付宝支付"
      this.pay = "支付宝支付";
      this.action = urlaction;
    } else if (urlaction == 3) {
      this.action = urlaction;
      this.channel_type = 3;
    }
    if (this.$route.params.payInfo) {
      let payInfo = JSON.parse(this.$route.params.payInfo);
      for (const key in payInfo) {
        if (Object.hasOwnProperty.call(payInfo, key)) {
          const item = payInfo[key];
          this[key] = item;
        }
      }
      //这边重新计算积分
      this.getCustomerScoreInfo().then((res) => {
        this.cacaulteScore();
      });
    } else {
      // this.getCustomerScoreInfo(); //更新用户积分
      this.subData.merchant_id = this.$route.query.merchant_id;
      this.subData.booth_id = this.$route.query.booth_id;
      if(this.$route.query.qr_uuid){
        this.subData.qr_uuid = this.$route.query.qr_uuid;
      }
    }
    this.getMerchantInfo(this.subData.merchant_id, this.subData.booth_id,this.subData.qr_uuid);
    if (urlaction == 1) {
      this.getquery_can_get_couponinfo();
    }
  },
  mounted() {
    document.getElementsByClassName("pay")[0].style.height =
      document.body.clientHeight + "px";
    document.getElementsByClassName("pop_remarks")[0].style.height =
      document.body.clientHeight + "px";
  },
  filters: {
    //自动过滤小数部分为0的情况
    filterZero(value) {
      if (value.toString().indexOf(".") > -1) {
        return value.toString().split(".")[1] == 0
          ? value.toString().split(".")[0]
          : value;
      } else {
        return value;
      }
    },
  },
  watch: {},
  methods: {
    //击了支付弹框
    pop_up() {
      //console.log("点击了支付弹框");
      //支付金额
      if (this.subData.pay_amount > 0) {
        //当前环境为微信的时候
        if (this.action == 1) {
          this.cacaulteScore();
        }
        //是否启用市场积分且每日用的次数不超过限制次数
      }
    },
    //添加备注
    handleClickRemarks() {
      this.note = this.subData.note || "";
      this.showNote = true;
    },

    // 点击了弹窗的确认按钮
    handleSubmitRemarks() {
      this.subData.note = this.note;
      this.showNote = false;
    },

    // 数据输入中
    handleChangeGoodInput(val) {
      this.subData.pay_amount = val;
      this.pay_amount = val;
    },
    //判读当前页面是什么跳转过来的
    browserJudgment() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("微信端");
        return "1";
      } else if (ua.match(/Alipay/i) == "alipay") {
        console.log("支付宝端");
        return "2";
      } else {
        console.log("其他浏览器");
        return "3";
      }
    },

    //支付类型发生变动
    payment_close() {
      this.payment_show = false;
      this.pay_show = true;
    },
    radioradio(e) {
      this.payment_show = false;
    },
    // 点击了弹窗的取消按钮
    handleCancelRemarks() {
      setTimeout(() => {
        this.note = "";
      }, 300);
      this.showNote = false;
    },

    //获取商户积分信息
    getCustomerScoreInfo() {
      return new Promise((resolve, reject) => {
        getCustomerScore({
          mer_id: this.subData.merchant_id,
          booth_id: this.subData.booth_id,
        }).then((res) => {
          if (res.resultCode == 1) {
            //获取对应的余额
            this.wallet = res.resultInfo.finance;
            // console.log(res);
           // console.log(res.resultInfo.finance);

            this.market_score_conf.use_market_score = res.resultInfo
              .market_score_conf.use_market_score
              ? 1
              : 0;
            if (this.market_score_conf.use_market_score) {
              this.market_score_conf = res.resultInfo.market_score_conf;
            }
            this.market_score_conf.use_m_score = res.resultInfo
              .market_score_conf.use_m_score
              ? 1
              : 0;
            this.merchant_score_conf = res.resultInfo.merchant_score_conf;
            //市场积分和 商户积分
            this.market_score = res.resultInfo.market_score;
            this.merchant_score = res.resultInfo.merchant_score;
            //市场积分和 商户积分使用次数
            this.market_score_use_number =
              res.resultInfo.market_score_use_number;
            this.merchant_score_use_number =
              res.resultInfo.merchant_score_use_number;
            resolve("1111");
          }
        });
      });
    },

    getquery_can_get_couponinfo() {
      getquery_can_get_coupon({
        merchant_id: this.subData.merchant_id,
        booth_id: this.subData.booth_id,
      }).then((res) => {});
    },
    setUUid() {
      var iscookie = document.cookie;
      if (iscookie) {
        let decodedCookie = decodeURIComponent(iscookie);
        let decodedCookievalue = decodedCookie.split(";");
        for (const key in decodedCookievalue) {
          if (decodedCookievalue[key].includes("uuidvl")) {
            cavalue = decodedCookievalue[key].split("=");
          }
        }
        if (cavalue && cavalue[0] == "uuidvl") {
          this.uuidvalue = cavalue[1];
        } else {
          let uuid = uuidv4();
          document.cookie = `uuidvl=${uuid}; expires=Thu, 18 Dec 2122 12:00:00 GMT; path=/`;
          this.uuidvalue = uuid;
        }
      } else {
        let uuid = uuidv4();
        document.cookie = `uuidvl=${uuid}; expires=Thu, 18 Dec 2122 12:00:00 GMT; path=/`;
        this.uuidvalue = uuid;
      }
      console.log("当前uuid为:" + this.uuidvalue);
    },
    pay_close() {
      this.pay_show = false;
      //选择优惠券的时候可能重新定义了需要重置为0
      this.select_ticket_index = 0;
      this.select_merchant_ticket_index = 0;
    },
    handleChangeUseMerchantScore(val) {
      this.cacaulteScore();
    },
    handleChangeUseMarketScore(val) {
      this.cacaulteScore();
    },
    //2022.4.17
    xz_payment() {
      this.payment_show = true;
      this.pay_show = false;
    },

    //计算会员积分和商户积分
    cacaulteScore() {
      this.amount = 0; //优惠重置
      this.pay_amount = this.subData.pay_amount; //付款金额重置
      this.market_score_amount = 0;
      this.market_score_max = 0;
      this.merchant_score_amount = 0;
      this.merchant_score_cost = 0;
      if (
        //this.customer_use_market_score == "1" &&
        this.market_score_conf.use_market_score == 1 &&
        parseInt(this.market_score_conf.market_score_every_day_max) >
          parseInt(this.market_score_use_number)
      ) {
        //计算下市场积分可以抵扣的金额数
        let market_score_amount = (
          Number.parseFloat(this.market_score) /
          Number.parseFloat(this.market_score_conf.market_score)
        ).toFixed(2);
        let market_score_cost = this.market_score;
        //市场积分抵扣金额 不得大于市场积分最大抵扣金额
        if (
          Number.parseFloat(market_score_amount) >
          Number.parseFloat(this.market_score_conf.market_score_max)
        ) {
          market_score_amount = Number.parseFloat(
            this.market_score_conf.market_score_max
          ).toFixed(2);
          market_score_cost = (
            Number.parseFloat(market_score_amount) *
            Number.parseFloat(this.market_score_conf.market_score)
          ).toFixed(3);
          this.market_score_amount_expect = market_score_amount;
          this.market_score_cost_expect = market_score_cost;
        } else {
          this.market_score_amount_expect = market_score_amount;
          this.market_score_cost_expect = market_score_cost;
        }
        //如果支付金额小于市场积分抵扣金额
        if (parseFloat(this.pay_amount) < parseFloat(market_score_amount)) {
          market_score_amount = this.pay_amount;
          market_score_cost = (
            Number.parseFloat(market_score_amount) *
            Number.parseFloat(this.market_score_conf.market_score)
          ).toFixed(3);
        }
        if (this.customer_use_market_score == 1) {
          this.market_score_amount = market_score_amount;
          this.market_score_cost = market_score_cost;
          this.amount = (
            Number.parseFloat(this.amount) +
            Number.parseFloat(this.market_score_amount)
          ).toFixed(2);
          this.pay_amount = (
            parseFloat(this.subData.pay_amount) - parseFloat(this.amount)
          ).toFixed(2);
        } else {
          this.market_score_cost = 0;
        }
      }
      //是否使用商户积分，剩余金额大于0，并且每日使用市场档口积分次数不超过限制次数
      if (
        this.market_score_conf.use_m_score == 1 &&
        this.merchant_score_conf &&
        parseInt(this.merchant_score_use_number) <
          parseInt(this.merchant_score_conf.m_score_every_day_max)
      ) {
        let merchant_score_amount = (
          parseFloat(this.merchant_score) /
          parseFloat(this.merchant_score_conf.score)
        ).toFixed(2);
        let merchant_score_cost = this.merchant_score;
        //商户积分抵扣金额 不得大于商户积分最大抵扣金额
        if (
          parseFloat(merchant_score_amount) >
          parseFloat(this.merchant_score_conf.m_score_max)
        ) {
          merchant_score_amount = parseFloat(
            this.merchant_score_conf.m_score_max
          ).toFixed(2);
          merchant_score_cost = (
            parseFloat(merchant_score_amount) *
            parseFloat(this.merchant_score_conf.score)
          ).toFixed(3);
          this.merchant_score_amount_expect = merchant_score_amount;
          this.merchant_score_cost_expect = merchant_score_cost;
        } else {
          this.merchant_score_amount_expect = merchant_score_amount;
          this.merchant_score_cost_expect = merchant_score_cost;
        }
        //
        if (this.customer_use_market_score == 0) {
          this.market_score_cost = 0;
        }
        if (this.customer_use_merchant_score == 0) {
          this.merchant_score_cost = 0;
        }

        //如果支付金额小于商户积分抵扣金额
        if (parseFloat(this.pay_amount) < parseFloat(merchant_score_amount)) {
          merchant_score_amount = this.pay_amount;
          merchant_score_cost = (
            parseFloat(merchant_score_amount) *
            parseFloat(this.merchant_score_conf.score)
          ).toFixed(3);
        }
        if (this.customer_use_merchant_score == 1) {
          this.merchant_score_amount = merchant_score_amount;
          this.merchant_score_cost = merchant_score_cost;
          this.amount = (
            Number.parseFloat(this.amount) +
            Number.parseFloat(this.merchant_score_amount)
          ).toFixed(2);
          this.pay_amount = (
            parseFloat(this.subData.pay_amount) - parseFloat(this.amount)
          ).toFixed(2);
        } else {
          this.merchant_score_cost = 0;
        }
      }
      getdiscountcouponlist({
        is_mini: 0,
        market_id: this.subData.market_id,
        mid: this.subData.mid,
        money: this.pay_amount,
        status: 0,
        from: 0,
        page: 1,
        pageSize: 200,
      }).then((res) => {
        if (res.resultCode == 1) {
          this.listarr = res.resultInfo.list;
          this.caucateTicket();
        }
      });
    },
    //计算档口卷
    caucateMerchantTicket() {
      if (this.select_merchant_ticket_index < 0) {
        this.subData.merchant_trade_no = "";
        let max_ticket_amount = 0;
        let work_count = 0;
        this.listmerchantarr.forEach((item) => {
          if (item.enable == 1) {
            work_count += 1;
            if (parseFloat(item.amount) > max_ticket_amount) {
              max_ticket_amount = parseFloat(item.amount);
            }
          }
        });
        if (max_ticket_amount > 0)
          this.subData.merchant_title =
            "有张" +
            work_count +
            "代金券可以使用,最高享" +
            max_ticket_amount +
            "元优惠";
        else {
          this.subData.merchant_title = "您暂无代金券可使用！";
          this.subData.merchant_trade_no = "";
        }
        this.ticket_merchant_amount = 0;
      } else {
        if (this.listmerchantarr.length == 0) {
          this.subData.merchant_title = "您暂无代金券可使用！";
          this.subData.merchant_trade_no = "";
          this.ticket_merchant_amount = 0;
          this.select_merchant_ticket_index = -1;
        } else {
          if (
            this.listmerchantarr[this.select_merchant_ticket_index].enable == 0
          ) {
            this.subData.merchant_title = "您暂无代金券可使用！";
            this.subData.merchant_trade_no = "";
            this.ticket_merchant_amount = 0;
            this.select_merchant_ticket_index = -1;
          } else if (
            this.listmerchantarr[this.select_merchant_ticket_index].enable == 1
          ) {
            if (
              parseFloat(this.pay_amount) <
              parseFloat(
                this.listmerchantarr[this.select_merchant_ticket_index].amount
              )
            ) {
              this.amount = (
                Number.parseFloat(this.amount) +
                Number.parseFloat(this.pay_amount)
              ).toFixed(2);
              this.ticket_merchant_amount = Number.parseFloat(
                this.pay_amount
              ).toFixed(2);
            } else {
              this.amount = (
                Number.parseFloat(this.amount) +
                Number.parseFloat(
                  this.listmerchantarr[this.select_merchant_ticket_index].amount
                )
              ).toFixed(2);
              this.ticket_merchant_amount = Number.parseFloat(
                this.listmerchantarr[this.select_merchant_ticket_index].amount
              ).toFixed(2);
            }
            this.subData.amount = this.amount; //优惠的钱
            this.subData.merchant_title =
              this.listmerchantarr[
                this.select_merchant_ticket_index
              ].ticketInfo.title;
            this.subData.merchant_trade_no =
              this.listmerchantarr[this.select_merchant_ticket_index].trade_no;
            this.pay_amount = (
              parseFloat(this.subData.pay_amount) - parseFloat(this.amount)
            ).toFixed(2);
          }
        }
      }
    },
    //计算优惠券
    caucateTicket() {
      if (this.select_ticket_index < 0) {
        this.subData.trade_no = "";
        let max_ticket_amount = 0;
        let work_count = 0;
        this.listarr.forEach((item) => {
          if (item.enable == 1) {
            work_count += 1;
            if (parseFloat(item.amount) > max_ticket_amount) {
              max_ticket_amount = parseFloat(item.amount);
            }
          }
        });
        if (max_ticket_amount > 0)
          this.subData.title =
            "有张" +
            work_count +
            "代金券可以使用,最高享" +
            max_ticket_amount +
            "元优惠";
        else {
          this.subData.title = "您暂无代金券可使用！";
          this.subData.trade_no = "";
        }
        this.ticket_amount = 0;
      } else {
        if (this.listarr.length == 0) {
          this.subData.title = "您暂无代金券可使用！";
          this.subData.trade_no = "";
          this.ticket_amount = 0;
        } else {
          if (this.listarr[this.select_ticket_index].enable == 0) {
            this.subData.title = "您暂无代金券可使用！";
            this.subData.trade_no = "";
            this.ticket_amount = 0;
          } else if (this.listarr[this.select_ticket_index].enable == 1) {
            if (
              parseFloat(this.pay_amount) <
              parseFloat(this.listarr[this.select_ticket_index].amount)
            ) {
              this.amount = (
                Number.parseFloat(this.amount) +
                Number.parseFloat(this.pay_amount)
              ).toFixed(2);
              this.ticket_amount = Number.parseFloat(this.pay_amount).toFixed(
                2
              );
            } else {
              this.amount = (
                Number.parseFloat(this.amount) +
                Number.parseFloat(this.listarr[this.select_ticket_index].amount)
              ).toFixed(2);
              this.ticket_amount = Number.parseFloat(
                this.listarr[this.select_ticket_index].amount
              ).toFixed(2);
            }
            this.subData.amount = this.amount;
            this.subData.title =
              this.listarr[this.select_ticket_index].ticketInfo.title;
            this.subData.trade_no =
              this.listarr[this.select_ticket_index].trade_no;
            this.pay_amount = (
              parseFloat(this.subData.pay_amount) - parseFloat(this.amount)
            ).toFixed(2);
          }
        }
      }
      //档口卷的计算逻辑
      getdiscountcouponlist({
        is_mini: 0,
        market_id: this.subData.market_id,
        mid: this.subData.mid,
        money: this.pay_amount,
        from: 1,
        status: 0,
        page: 1,
        pageSize: 200,
      }).then((res) => {
        if (res.resultCode == 1) {
          this.listmerchantarr = res.resultInfo.list;
          this.caucateMerchantTicket();
        }
      });

      console.log(this.radio);
      console.log(this.pay_amount);
      //是否可以使用余额
      if(this.wallet*1>=this.pay_amount*1){
        this.isdisabled=false;
        this.pay_walletamount=this.pay_amount
      }else{
        this.isdisabled=true;
        this.radio=this.pay;
         this.pay_walletamount=0
      }
    },

    //点击确定按钮准备进行支付
    handleChangeGoodFinish() {
      try {
        this.pay_show = true; //打开支付
        this.amount = 0;
        //当前环境为微信的时候
        if (this.action == 1) {
          this.getCustomerScoreInfo().then((res) => {
            this.cacaulteScore();
          });
        } else {
        }
        this.baseUri = baseUri; //获取当前的url
        this.browserJudgment();
      } catch (e) {
        alert(JSON.stringify(e));
      }
    },

    //跳转到商户档口列表
    isMerchantTikcetsList() {
      if (this.subData.pay_amount >= 0) {
        //这边处理下没有优惠券的情况
        if (this.select_merchant_ticket_index > -1) {
          //实付金额+优惠券金额
          this.pay_amount_no_ticket = (
            parseFloat(this.pay_amount) +
            parseFloat(
              this.listmerchantarr[this.select_merchant_ticket_index].amount
            )
          ).toFixed(2);
        } else {
          this.pay_amount_no_ticket = this.pay_amount;
        }
        let query = {
          pay_amount_no_ticket: this.pay_amount_no_ticket,
          pay_show: this.pay_show,
          subData: this.subData,
          select_ticket_index: this.select_ticket_index,
          customer_use_market_score: this.customer_use_market_score,
          customer_use_merchant_score: this.customer_use_merchant_score,
          select_merchant_ticket_index: this.select_merchant_ticket_index,
          from: 1,
        };
        this.$router.push({
          name: "couponslist",
          path: "/couponslist",
          query: { data: JSON.stringify(query) },
        });
      }
    },
    //跳转逻辑
    isclickfliter() {
      if (this.subData.pay_amount >= 0) {
        //这边处理下没有优惠券的情况
        if (this.select_ticket_index > -1) {
          //实付金额+优惠券金额
          this.pay_amount_no_ticket = (
            parseFloat(this.pay_amount) +
            parseFloat(this.listarr[this.select_ticket_index].amount)
          ).toFixed(2);
        } else {
          this.pay_amount_no_ticket = this.pay_amount;
        }
        //
        // customer_use_market_score: "1",
        // customer_use_merchant_score: "1",
        //
        let query = {
          pay_amount_no_ticket: this.pay_amount_no_ticket,
          pay_show: this.pay_show,
          subData: this.subData,
          select_ticket_index: this.select_ticket_index,
          customer_use_market_score: this.customer_use_market_score,
          customer_use_merchant_score: this.customer_use_merchant_score,
          from: 0,
        };
        this.$router.push({
          name: "couponslist",
          path: "/couponslist",
          query: { data: JSON.stringify(query) },
        });
      }
    },

    // 获取商户信息
    getMerchantInfo(merchant_id, booth_id,qr_uuid) {
      pay_getMerchantInfo({
        merchant_id: merchant_id,
        booth_id: booth_id,
        qr_uuid:qr_uuid
      }).then((res) => {
        this.merchant_info = res.resultInfo;
        this.loading = false;
        this.subData.market_id = res.resultInfo.market_id;
        this.subData.mid = res.resultInfo.mid;
      });
    },

    //前往支付
    payment() {
  const toast = Toast.loading({
  duration: 0, // 持续展示 toast
  forbidClick: true,
  mask:true,
  message: '加载中...',
  selector: '#custom-selector',
});
      this.baseUri = baseUri; //获取当前的url
      this.payChannel = this.browserJudgment();
      if (
        this.subData.pay_amount <= 0 ||
        this.pay_amount <= 0 ||
        !this.subData.pay_amount ||
        !this.pay_amount
      ) {
        if (
          this.market_score_cost == 0 &&
          this.merchant_score_cost == 0 &&
          this.subData.trade_no == "" &&
          this.subData.merchant_trade_no == ""
        ) {
          this.$toast.fail("请输入正确的支付金额");
          return false;
        }
      }
      let trade_tickets = [];
      if (this.subData.trade_no) {
        trade_tickets.push(this.subData.trade_no);
      }
      if (this.subData.merchant_trade_no) {
        trade_tickets.push(this.subData.merchant_trade_no);
      }


      //支付前判断当前的支付方式
      if (this.radio == "余额支付") {
        if (this.pay_amount * 1 > this.wallet * 1) {
          this.$toast.fail("支付失败，余额不足！");
          return false;
        }
      } else {
        this.pay_walletamount =0;
      }
      requestPay({
        qr_uuid:this.subData.qr_uuid,
        wallet:this.pay_walletamount, //余额支付传参
        booth_id: this.subData.booth_id,
        mer_id: this.subData.merchant_id,
        market_user_score: this.market_score_cost,
        merchant_user_score: this.merchant_score_cost,
        trade_no: trade_tickets.length > 0 ? trade_tickets.join(",") : "", //优惠券编号
        pay_money: this.subData.pay_amount,
        type: this.payChannel == 1 ? "wechat" : "alipay",
      }).then((res) => {
        let _this=this
        if (res.resultCode == 1) {
           Toast.clear();
          if (
            res.resultInfo.data.code != null &&
            res.resultInfo.data.code == 0
          ) {

            setCookie("order_no", res.resultInfo.data.order_no);
            window.localStorage.setItem(
              "order_no",
              res.resultInfo.data.order_no
            );
            // this.$router.replace({
            //   path: "/finish_payment",
            //   query: { out_trade_no: res.resultInfo.data.order_no },
            // });
      
          } else {
            if (this.payChannel == 1) {
              let payInfo = res.resultInfo.data.payInfo;
              window.localStorage.setItem(
                "order_no",
                res.resultInfo.data.cpTranNo
              );
              window.WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                payInfo,
                function (res) {
                  if (res.err_msg === "get_brand_wcpay_request:ok") {
                    this.$toast.success("支付成功");
                  } else {
                    this.$toast.fail(res.err_msg);
                  }
                }
              );
            } else {
              let payInfo = res.resultInfo.data.payInfo;
              window.AlipayJSBridge.call(
                "tradePay",
                {
                  tradeNO: payInfo.trade_no, 
                },
                function (result) {
                  if (result.resultCode == "9000") {
                    AlipayJSBridge.call('closeWebview')
                  } else {
                    this.$toast.fail(result.resultMsg);
                  }
                }
              );
            }
          }
        } else {
          Toast.clear();
          this.$toast.fail(res.resultMsg);
          this.$router.go(0)
        }
      });
    },

    // （未用）
    popup() {
      if (this.action == 1) {
        if (this.subData.pay_amount > 0) {
          this.show = false;
        } else {
          this.subData.amount = 0;
          (this.amount = ""),
            getdiscountcouponlist({
              is_mini: 0,
              market_id: this.subData.market_id,
              mid: this.subData.mid,
              money: this.subData.pay_amount,
              status: 0,
              page: 1,
              pageSize: 200,
            }).then((res) => {
              if (res.resultCode == 1) {
                let numbervalue = res.resultInfo.totalCount;
                if (res.resultInfo.totalCount > 0) {
                  if (res.resultInfo.list[0].enable == 1) {
                    this.subData.title = `您有${numbervalue}代金券可使用！`;
                  } else if (res.resultInfo.list[0].enable == 0) {
                    this.subData.title = `有${numbervalue}张代金券`;
                  }
                } else {
                  this.subData.title = "您暂无代金券可使用！";
                }
              }
            });
        }
      } else {
        console.log("当前不是微信环境");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.zf {
  width: 3.5rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #6dd572 0%, #58b85c 100%);
  border-radius: 0.16rem;
  outline: none;
  border: none;
  margin-right: 0.3rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.pay_class {
  width: 100vw;
  margin: top 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pay_item {
  width: 0.08rem;
  height: 0.36rem;
  background: #58b85c;
  border-radius: 1px;
  // margin-left: 0.3rem;
}
.pay_item2 {
  margin-left: 0.1rem;
}
.pay_item3 {
  margin-left: 0.1rem;
}
.pay_class2 {
  margin-top: 0.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bottomconcent1 {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-evenly;
  margin-left: 0.3rem;
}
.bottomconcent2 {
  flex: 1;
}
.bottomconcent1_1 {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}
.qian {
  font-size: 0.4rem;
  font-family: OPPOSans-B, OPPOSans;
  font-weight: normal;
  color: #ff0000;
}
.bottomconcent1_2 {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.key_box {
  width: 100%;
  height: 4.8rem;
}
.remark_btn {
  width: 100%;
  // text-align: center;
  text-align: right;
  display: inline-block;
  font-size: 0.28rem;
  color: #259c8a;
  line-height: 0.8rem;
  span {
    padding-right: 0.1rem;
  }
}
.pay {
  background-color: #9c5525;
  height: 100vh;
  width: 100vw;
  position: relative;
  font-size: 0.28rem;
  overflow: hidden;
  background-color: #ffffff;
  .loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // 基础

  .box {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    .top {
      text-align: center;
      color: #333;
      font-weight: bold;
      padding: 0 0.3rem;
      // padding-top: 1.5rem;
      padding-top: 1rem;
      .name {
        font-size: 0.58rem;
        line-height: 0.7rem;
        margin-bottom: 0.3rem;
      }
      .label {
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333;
        font-weight: bold;
        line-height: 0.5rem;
        // margin-bottom: 0.6rem;
        margin-bottom: 0.2rem;
      }
      .voucher {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .voucheritem {
        width: 6.8rem;
        height: 0.8rem;
        background: linear-gradient(
          135deg,
          #ffdfcb 0%,
          #ffece2 49%,
          #ffd6ba 100%
        );
        border-radius: 0.16rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.26rem;
      }
      .voucheritem1 {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #865002;
        margin-left: 0.2rem;
      }
      .voucheritem2 {
        margin-right: 0.2rem;
        font-size: 0.28;
        font-family: OPPOSans-B, OPPOSans;
        font-weight: 500;
        color: #ff0000;
      }
      .input_boxitem {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .input_box {
        width: 6.9rem;
        height: 1.22rem;
        background: #f9f9f9;
        border-radius: 0.16rem;
        //margin-top: 0.2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .input_mark {
          font-size: 0.58rem;
          margin-left: 0.2rem;
        }
        .input_number {
          font-size: 0.65rem;
          height: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          & > span {
            width: auto;
            margin-right: 0.05rem;
          }
          .cursor {
            display: inline-block;
            width: 0.03rem;
            height: 0.5rem;
            background-color: #31b158;
            // position: absolute;
            // left: 0rem;
            // top: 50%;
            // transform: translateY(-50%);
            animation: inputFocus 0.8s linear infinite;
            @keyframes inputFocus {
              0% {
                opacity: 1;
              }
              50% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }
        }
      }
      .tips {
        color: #999;
        font-size: 0.28rem;
        text-align: right;
        font-weight: normal;
      }
    }
    .bottom {
      width: 100vw;
      height: 1.28rem;
      justify-content: space-between;
      display: flex;
      align-items: center;
      background: #ffffff;
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      border: solid 1px #eeeeee;
      .pay_btn {
        width: 100%;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.36rem;
        font-weight: bold;
        color: #fff;
        background: #259c8a;
        border-radius: 0.1rem;
      }
      .key_box {
        width: 100%;
        height: 4.8rem;
      }
    }
  }
  // 弹窗相关
  .pop_remarks {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 3rem;
    transition: 0.3s all;
    display: flex;
    align-items: center;
    justify-content: center;
    .remarks_box {
      width: 5.4rem;
      border-radius: 0.1rem;
      background-color: #fff;
      .title {
        font-size: 0.36rem;
        color: #333;
        font-weight: bold;
        text-align: center;
        padding-top: 0.36rem;
        letter-spacing: 0.02rem;
      }
      .input {
        padding: 0.3rem;
        input {
          width: 100%;
          background-color: #f5f5f5;
          border-radius: 0.1rem;
          height: 0.8rem;
          line-height: 0.5rem;
          color: #333;
          border: none;
          outline: none;
          text-indent: 0.2rem;
          font-size: 0.28rem;
        }
      }
      .btns {
        font-size: 0.32rem;
        color: #259c8a;
        display: flex;
        align-items: center;
        border-top: 0.01rem solid #ccc;
        letter-spacing: 0.02rem;
        font-weight: bold;
        span {
          width: 50%;
          height: 0.8rem;
          line-height: 0.8rem;
          text-align: center;
          &:nth-child(1) {
            box-sizing: border-box;
            border-right: 0.01rem solid #ccc;
          }
        }
      }
    }
  }
}
/*微信提示 */
.tips {
  color: #999;
  font-size: 0.26rem;
  text-align: right;
  font-weight: normal;
  margin-top: 0.2rem;
}

@w: 0.0181rem;
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//支付弹框
.heig {
  height: fit-content;
}
.heig2 {
  height: 280 * @w;
}
.pay_num {
  width: 100%;
  height: 80 * @w;
  margin-top: 40 * @w;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #eeeeee solid;
  .pay_num_1 {
    font-size: 16 * @w;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .pay_num_2box {
    display: flex;
    justify-content: center;
    align-items: center;
    .pay_num_2 {
      font-size: 34 * @w;
      font-family: OPPOSans-B, OPPOSans;
      font-weight: 600;
      color: #4a4a4a;
      span {
        font-size: 28 * @w;
        font-weight: 500;
      }
    }
  }
}
.pay_button {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80 * @w;
  bottom: 0;
  .pay_but {
    width: 345 * @w;
    height: 44 * @w;
    background: linear-gradient(90deg, #6dd572 0%, #58b85c 100%);
    border-radius: 8 * @w;
    border: none;
    font-size: 16 * @w;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20 * @w;
  }
}
.colorstyle {
  color: #259c8a;
}
.order {
  font-size: 16 * @w;
  color: #969799;
  width: 100%;
  height: 80 * @w;
  margin-top: 40 * @w;
  height: 40 * @w;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  span {
    font-size: 16 * @w;
    font-weight: 500;
    color: rgb(13, 12, 12);
  }
}

.wx_title {
  width: 100%;
  display: flex;
  align-items: center;
  .wx {
    width: 30 * @w;
    margin-right: 20 * @w;
  }
}

.discounts {
  font-size: 12 * @w !important;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff0000;
}

/deep/.van-badge--fixed {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(95%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}

.custom_item {
  font-size: 16 * @w;
  font-weight: 400;
  color: #2e3033;
}
.custom-title {
  letter-spacing: 0.1em;
  font-size: 14 * @w !important;
  font-weight: 400;
  color: #9ea5b8 !important;
}
.custom-title2 {
  font-size: 16 * @w;
  font-weight: 400;
  color: #2e3033;
}

.style_red {
  color: #ff0000;
}
</style>